/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
  faHeart,
  faShoppingBag,
  faUserCircle,
  faTrashAlt,
  faPlusCircle,
  faMinusCircle,
  faTags,
  faSignOut,
  faArrowRight,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";

import {
  BusinessInterface,
  CategoryInterface,
  ProductInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";
import APIServer from "../services/APIServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardCategory } from "../components/cards/CardCategory";
import CardProduct from "../components/cards/CardProduct";
import ProductForCategory from "../components/ProductForCategory";
import ModalProduct from "../components/modals/modalProduct/ModalProduct";
import ModalGallery from "../components/ModalGallery";
import Loading from "../components/Loading";
import {
  SumAddons,
  classNames,
  filterOrdenStatus,
  findMatchingProduct,
  formatDateTime,
  normalizeString,
  printPrice,
  printPriceWithCommasAndPeriods,
  scrollToAmount,
  scrollToSection,
  scrollToTop,
  veryfingIfRecreateTppLink,
} from "../utils/functions";
import PageNotFound from "../components/PageNotFound";
import imageDefault from "../assets/jpg/no-imagen-shop.jpg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setResultState,
  setSearchInputValue,
} from "../store/slices/resultsReducer";
import ModalRegister from "../components/modals/modalLoginRegister/ModalRegister";
import ModalLogIn from "../components/modals/modalLoginRegister/ModalLogIn";
import Modal from "../components/modals/GenericModal";
import ProfileInfo from "../container/profile/ProfileInfo";
import OrdersHistory from "./OrdersHistory";
import PaymentWayForm from "../container/paymentWayForm/PaymentWayForm";
import { useAppSelector } from "../store/hooks";
import useServer from "../services/useServerMain";
import { ProductCartInterface } from "../interfaces/LocalInterfaces";
import {
  setBusinessId,
  setCurrentCurrency,
  setUser,
  set_availableCurrencies,
  set_minimun_amount_to_buy_with_delivery,
} from "../store/slices/SessionSlice";
import ModalCheckoutForm from "../components/ModalCheckoutForm";
import { useServerOrders } from "../services/useServerOrders";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  setInitProductsCar,
  setInitModalShowed,
} from "../store/slices/InitSlice";
import Footer from "../components/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { setClearCarShop } from "../store/slices/OrdersSlice";

interface PersonalInfoInterface {
  name: string;
  description: string;
  address: string;
}

export const BusinessShopPage = () => {
  const { initProductsCar, initModalShowed } = useAppSelector(
    (state: { init: any }) => state.init
  );
  const { key, user, currentCurrency, minimun_amount_to_buy_with_delivery } =
    useAppSelector((state: { session: any }) => state.session);

  const { logOut } = useServer();
  const { CancelOrder, CreateTropipayLink, getMyOrders } = useServerOrders();

  const slug = "gelato";
  const { search } = useLocation();

  function evaluateDefaultCurrency(query: string): string | null {
    const match = query.match(/\?defaultCurrency=([^&]+)/);
    return match ? match[1] : null;
  }

  const haveDefaultCurrency = evaluateDefaultCurrency(search);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [business, setBusiness] = useState<BusinessInterface>();
  const [suggestedProducts, setSuggestedProducts] = useState<
    Array<ProductInterface>
  >([]);
  const [cartShopping, setCartShopping] = useState<Array<ProductCartInterface>>(
    initProductsCar?.find(
      (element: { businessName: string | undefined }) =>
        element.businessName === business?.name
    )?.initProductsCar! ?? []
  );
  const [onSaleProducts, setOnSaleProducts] = useState<Array<ProductInterface>>(
    []
  );
  const [saleCategories, setSaleCategories] = useState<
    Array<CategoryInterface>
  >([]);

  const { orders, clearCarShop } = useAppSelector((state) => state.orders);

  const [actualView, setActualView] = useState<string>("shop");

  const [availabilityAlert, setAvailabilityAlert] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [openLogOutModal, setOpenLogOutModal] = useState<boolean>(false);
  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);
  const [showModalLogIn, setShowModalLogIn] = useState<boolean>(false);

  const [showModalCheckoutForm, setShowModalCheckoutForm] =
    useState<boolean>(false);
  const [deleteOrderModal, setDeleteOrderModal] = useState<{
    state: boolean;
    id: number | null;
  }>({
    state: false,
    id: null,
  });
  const [seletedProduct, setSeletedProduct] = useState<ProductInterface | null>(
    null
  );
  const [products, setProducts] = useState<Array<StoreSections>>([]);
  const [showModalGallery, setShowModalGallery] = useState<boolean>(false);
  const [pageNotFound404, setPageNotFound404] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [userOpen, setUserOpen] = useState<boolean>(false);
  const [isLoggingToPay, setIsLoggingToPay] = useState<boolean>(false);

  const [subTotal, setSubTotal] = useState<
    { prices: number; codeCurrency: string }[]
  >([]);

  const [productResults, setProductResults] = useState<ProductInterface[]>();
  const [
    products_with_no_salesCategories,
    set_products_with_no_salesCategories,
  ] = useState<StoreSections>();

  const searchResultsActive = useSelector((state: RootState) => state.UI.value);

  const searchInputValue = useSelector(
    (state: RootState) => state.UI.searchInputValue
  );
  const dispatch = useDispatch();

  const [online_shop_main_currency, set_online_shop_main_currency] = useState<
    { value: string; name: string; href: string; current: boolean }[]
  >([]);

  const [showInitModal, setshowInitModal] = useState({
    state: false,
    degree: false,
  });

  useEffect(() => {
    if (key !== null) {
      getMyOrders({ per_page: 1 });
    }

    if (
      actualView === "shop" &&
      initModalShowed !== true &&
      haveDefaultCurrency === null
    ) {
      const timer = setTimeout(() => {
        setshowInitModal({
          state: true,
          degree: false,
        });
        dispatch(setInitModalShowed(true));
      }, 40000); // 40 segundos

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.all([
        APIServer.get(`/public/business/${slug}`).then((resp) => {
          return resp;
        }),
        APIServer.get(`/public/categories/${slug}`),
        APIServer.get(`/public/products/business/${slug}?all_data=true`).then(
          (resp) => {
            return resp;
          }
        ),
      ])
        .then((resp) => {
          if (key === null) {
            dispatch(setUser(null));
          }

          const onlineShopMainCurrency =
            resp[0].data.configurationsKey
              .find(
                (config: { key: string }) =>
                  config.key === "online_shop_main_currency"
              )
              ?.value.split(",") ?? [];

          dispatch(set_availableCurrencies(resp[0]?.data?.availableCurrencies));

          let minimun_amount_to_buy_with_delivery =
            JSON.parse(
              resp[0].data.configurationsKey.find(
                (config: { key: string }) =>
                  config.key === "minimun_amount_to_buy_with_delivery"
              )?.value
            ) ?? [];

          if (Object.keys(minimun_amount_to_buy_with_delivery).length === 0) {
            minimun_amount_to_buy_with_delivery = [];
          }

          dispatch(
            set_minimun_amount_to_buy_with_delivery(
              minimun_amount_to_buy_with_delivery
            )
          );

          let setMainCurrencies = onlineShopMainCurrency.map(
            (config: any, index: number) => ({
              value: config,
              name: config,
              href: "#",
              current:
                !!haveDefaultCurrency &&
                  onlineShopMainCurrency.find(
                    (curr: any) => curr === haveDefaultCurrency
                  )
                  ? haveDefaultCurrency === config
                  : currentCurrency === config,
            })
          );

          if (
            setMainCurrencies.filter(
              (curr: { current: boolean }) => curr.current
            ).length === 0
          ) {
            setMainCurrencies[0].current = true;
          }

          dispatch(
            setCurrentCurrency(
              setMainCurrencies.find(
                (curr: { current: boolean }) => curr.current
              ).value
            )
          );

          set_online_shop_main_currency(setMainCurrencies);

          setBusiness(resp[0].data);
          dispatch(setBusinessId(resp[0].data.id));
          setSuggestedProducts(
            resp[2].data.products.filter(
              (prod: ProductInterface) => prod.suggested
            )
          );
          setOnSaleProducts(
            resp[2].data.products.filter(
              (prod: ProductInterface) => prod.onSale
            )
          );

          let store_sections: Array<StoreSections> = [];
          let products_without_salesCategories: StoreSections = {
            title: "SIN CATEGORÍA",
            id: 777,
            data: [],
          };
          //👇🏻Shop
          // resp[4].data.items.forEach((item: ProductInterface) => {
          //👇🏻Public
          resp[2].data.products.forEach((item: ProductInterface) => {
            //Find  if  category exist
            const found = store_sections.find(
              (section) => section.id === item.salesCategory?.id
            );

            if (found) {
              store_sections = store_sections.map((item_data) => {
                if (item_data.id === item.salesCategory?.id) {
                  return {
                    ...item_data,
                    data: [...item_data.data, item],
                  };
                }
                return item_data;
              });
            } else {
              if (item?.salesCategory !== null) {
                store_sections.push({
                  id: item.salesCategory?.id,
                  title: item.salesCategory?.name,
                  data: [item],
                });
              } else {
                products_without_salesCategories.data.push(item);
              }
            }
          });

          store_sections = store_sections
            .filter(
              (item) =>
                item.data.length > 0 &&
                item.title !== "Agregos" &&
                item.title !== "Guarniciones"
            )
            .sort((a, b) => {
              return a?.title?.toUpperCase() > b?.title?.toUpperCase() ? 1 : -1;
            });

          setSaleCategories(
            resp[1].data.filter((category: { id: any; name: string }) => {
              if (
                store_sections.filter(
                  (store: { id: any }) => store.id === category.id
                ).length >= 1 &&
                category.name !== "Agregos" &&
                category.name !== "Guarniciones"
              ) {
                return category;
              }
            })
          );

          set_products_with_no_salesCategories(
            products_without_salesCategories
          );
          setProducts(store_sections);
          setIsLoading(false);

          if (search !== "" && !haveDefaultCurrency) {
            dispatch(
              setSearchInputValue(
                search.split("?search=")[1]?.replaceAll("%20", " ")
              )
            );
          }
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
          setPageNotFound404(true);
        });
    })();
  }, []);

  useEffect(() => {
    if (searchInputValue !== "" && products.length > 0) {
      dispatch(setResultState(true));

      let productsResults: ProductInterface[] = [];

      products.map((prod) =>
        prod.data.map((dat) => {
          if (
            normalizeString(dat?.name ?? "").includes(
              normalizeString(searchInputValue ?? "")
            )
          ) {
            productsResults.push(dat);
          }
        })
      );

      setProductResults(productsResults);

      scrollToAmount(1300);
    }
  }, [products]);

  useEffect(() => {
    (async () => {
      const subTotalTemp: { [currency: string]: number } = {};
      for await (const product of cartShopping) {
        const quantity = product.quantity;
        const price = product.onSale
          ? product?.onSalePrice?.amount
          : product?.price?.price;
        const currency = product.price.codeCurrency;
        const addonPrice =
          product?.addons?.length! > 0
            ? SumAddons(product.addons!, currentCurrency!) * product.quantity
            : 0;

        subTotalTemp[currency] =
          (subTotalTemp[currency] || 0) + price * quantity + addonPrice;
      }

      const formattedSubtotal = Object.entries(subTotalTemp).map(
        ([codeCurrency, prices]) => ({
          prices,
          codeCurrency,
        })
      );

      setSubTotal(formattedSubtotal);
    })();
    setAvailabilityAlert(
      cartShopping.some(
        (product) =>
          product.quantity > product.quantityInStock &&
          product.type !== "SERVICE" &&
          product.type !== "ADDON" &&
          product.type !== "MENU"
      )
    );

    if (
      initProductsCar.some(
        (element: { businessName: string | undefined }) =>
          element.businessName === business?.name
      )
    ) {
      dispatch(
        setInitProductsCar(
          initProductsCar.map(
            (element: { businessName: string | undefined }) => {
              if (element.businessName === business?.name) {
                return {
                  businessName: business?.name,
                  initProductsCar: cartShopping,
                };
              } else {
                return element;
              }
            }
          )
        )
      );
    } else {
      dispatch(
        setInitProductsCar([
          ...initProductsCar,
          { businessName: business?.name, initProductsCar: cartShopping },
        ])
      );
    }
    // dispatch(setInitProductsCar(cartShopping))
  }, [cartShopping]);

  useEffect(() => {
    setCartShopping(
      initProductsCar?.find(
        (element: { businessName: string | undefined }) =>
          element.businessName === business?.name
      )?.initProductsCar! ?? []
    );
  }, [business]);

  useEffect(() => {
    setCartShopping([]);
    dispatch(setClearCarShop(false));
  }, [clearCarShop]);

  //Functions that take care of filtering products when changing the currency type
  const productsFilteredByCurrencyType = () => {
    return products.map((section) => {
      const filterData = section.data.filter((prod) => {
        if (prod.onSale) {
          return (
            prod.onSalePrice?.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          );
        } else {
          return prod.prices.some(
            (price) =>
              price.codeCurrency ===
              online_shop_main_currency.filter(
                (currency) => currency.current
              )[0].value
          );
        }
      });

      if (filterData.length >= 1) {
        return {
          id: section.id,
          title: section.title,
          data: filterData,
        };
      } else {
        return {
          id: 0,
          title: "",
          data: [],
        };
      }
    });
  };
  const suggestedProductsFilteredByCurrencyType = () => {
    return suggestedProducts.filter((prod) => {
      if (prod.onSale) {
        return (
          prod.onSalePrice?.codeCurrency ===
          online_shop_main_currency.filter((currency) => currency.current)[0]
            .value
        );
      } else {
        return prod.prices.some(
          (price) =>
            price.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
        );
      }
    });
  };

  const onSaleProductsFilteredByCurrencyType = () => {
    return onSaleProducts.filter((prod) => {
      if (prod.onSale) {
        return (
          prod.onSalePrice?.codeCurrency ===
          online_shop_main_currency.filter((currency) => currency.current)[0]
            .value
        );
      } else {
        return prod.prices.some(
          (price) =>
            price.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
        );
      }
    });
  };
  const products_with_no_salesCategories_FilteredByCurrencyType = () => {
    return {
      data: products_with_no_salesCategories?.data.filter((prod) => {
        if (prod.onSale) {
          return (
            prod.onSalePrice?.codeCurrency ===
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          );
        } else {
          return prod.prices.some(
            (price) =>
              price.codeCurrency ===
              online_shop_main_currency.filter(
                (currency) => currency.current
              )[0].value
          );
        }
      }),
      id: products_with_no_salesCategories?.id,
      title: products_with_no_salesCategories?.title,
    } as StoreSections;
  };
  //-----------------------------------------------------------
  const addCart = (
    value: ProductInterface,
    addCustomCakeText?: boolean,
    addVolcanicCandel?: boolean,
    addCeramicCandel?: boolean
  ) => {
    const getProductTemp = () => {
      return value.type === "VARIATION"
        ? cartShopping.find(
          (item) =>
            item.id === value.id && item.variationId === value.variationId
        )
        : cartShopping.find((item) => item.id === value.id);
    };

    const createNewProductTemp = (): ProductCartInterface => ({
      name: value.name,
      price: value.prices[0],
      quantity: 1,
      images: value.images[0],
      id: value.id,
      variationId: value.type === "VARIATION" ? value.variationId : null,
      suggested: value.suggested,
      onSale: value.onSale,
      onSalePrice: value.onSalePrice,
      quantityInStock: value.totalQuantity,
      type: value.type,
      addons: value?.addons || null,
      observations: value.observations ?? "",
    });

    const addAuxProduct = (
      aux: ProductCartInterface[],
      productId: number,
      currency: string
    ) => {
      const product = productsFilteredByCurrencyType()
        .flatMap((category) => category.data)
        .find((item) => item.id === productId);

      if (product) {
        const productPrices = product.prices.find(
          (price) => price.codeCurrency === currency
        );
        if (productPrices) {
          aux.push({
            id: product.id,
            images: product.images[0] || undefined,
            name: product.name,
            onSale: product.onSale,
            onSalePrice: product.onSalePrice,
            price: {
              price: productPrices.price,
              codeCurrency: productPrices.codeCurrency,
              id: 0,
              prices: 0,
              isMain: false,
              priceSystemId: 0,
            },
            quantity: 1,
            quantityInStock: product.quantityInStock,
            suggested: product.suggested,
            type: product.type,
            variationId: null,
            addons: null,
            observations: "",
          });
        }
      }
    };

    const addAuxProducts = (aux: ProductCartInterface[], currency: string) => {
      if (addCustomCakeText) {
        addAuxProduct(aux, 20118, currency);
        // addAuxProduct(aux, 19722, currency);
      }
      if (addVolcanicCandel) {
        addAuxProduct(aux, 23350, currency);
      }
      if (addCeramicCandel) {
        addAuxProduct(aux, 29734, currency);
      }
    };

    let productTemp = getProductTemp();

    if (!productTemp) {
      let newProductTemp = createNewProductTemp();
      let cartShoppingTemp;

      if (addCustomCakeText || addVolcanicCandel || addCeramicCandel) {
        let aux: ProductCartInterface[] = [];
        addAuxProducts(aux, currentCurrency!);
        cartShoppingTemp = [...cartShopping, newProductTemp, ...aux];
      } else {
        cartShoppingTemp = [...cartShopping, newProductTemp];
      }

      setCartShopping(cartShoppingTemp);
    } else {
      const cartShoppingTemp = cartShopping.map((item) =>
        item.id === productTemp!.id
          ? { ...item, quantity: productTemp!.quantity + 1 }
          : item
      );
      setCartShopping(cartShoppingTemp);
    }

    setOpen(true);
  };

  //----------------------------------------------------------
  // Definir una función que busque un producto por su ID
  const findProductById = (productId: number): ProductInterface | undefined => {
    // Buscar la StoreSections que contiene el producto
    const storeSectionContainingProduct = products.find((section) =>
      section.data.some((product) => product.id === productId)
    );

    // Si encontramos la sección, devolvemos el producto correspondiente
    if (storeSectionContainingProduct) {
      return storeSectionContainingProduct.data.find((product) => {
        if (product.id === productId) {
          return product;
        }
      });
    }

    // Si no se encuentra la sección o el producto, devolvemos undefined
    return undefined;
  };

  const quantityProduct = (
    value: ProductCartInterface,
    quantityValue: number
  ) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.type === "VARIATION") {
        if (
          item.id === productTemp?.id &&
          item.variationId === productTemp.variationId
        ) {
          return {
            ...item,
            quantity: quantityValue,
          };
        } else {
          return item;
        }
      } else {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: quantityValue,
          };
        } else {
          return item;
        }
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const removeQuantityCart = (value: ProductCartInterface) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.type === "VARIATION") {
        if (
          item.id === productTemp?.id &&
          item.variationId === productTemp.variationId
        ) {
          return {
            ...item,
            quantity: productTemp.quantity - 1,
          };
        } else {
          return item;
        }
      } else {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: productTemp.quantity - 1,
          };
        } else {
          return item;
        }
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const addProductCart = (value: ProductCartInterface) => {
    let productTemp = value;
    const cartShoppingTemp = cartShopping.map((item) => {
      if (item.type === "VARIATION") {
        if (
          item.id === productTemp?.id &&
          item.variationId === productTemp.variationId
        ) {
          return {
            ...item,
            quantity: productTemp.quantity + 1,
          };
        } else {
          return item;
        }
      } else {
        if (item.id === productTemp?.id) {
          return {
            ...item,
            quantity: productTemp.quantity + 1,
          };
        } else {
          return item;
        }
      }
    });
    setCartShopping(cartShoppingTemp);
  };

  const deletedProductFromCart = (value: ProductCartInterface) => {
    let cartShoppingTemp: ProductCartInterface[];

    if (value.type === "VARIATION") {
      cartShoppingTemp = cartShopping.filter(
        (item) => item.variationId !== value.variationId
      );
    } else {
      cartShoppingTemp = cartShopping.filter((item) => item.id !== value.id);
    }

    setCartShopping(cartShoppingTemp);
  };

  const sendWhatsApp = (values: PersonalInfoInterface) => {
    if (business?.phones.length !== 0) {
      const phoneToSend = business?.phones[0].number
        .replace(/[^\w\s]/g, "")
        .replace(/\s/g, "");
      let url = `https://api.whatsapp.com/send?phone=${phoneToSend}`;

      const listProducts = cartShopping
        .map((item) => {
          let itemPrice = item.price.price;
          if (item.onSale && item.onSalePrice) {
            itemPrice = item.onSalePrice?.amount;
          }
          return `(x${item.quantity}) ${item.name
            } - ${printPriceWithCommasAndPeriods(itemPrice * item.quantity)}`;
        })
        .join("\n");

      const cleanValue = (value: string) =>
        value.replace(/[^\w\s]/g, " ").replace(/\s/g, " ");

      const payload = `Hola!, quería ordenar un pedido.\n\nNombre: ${cleanValue(
        values.name
      )}\nDirección: ${cleanValue(values.address)}\nDescripción: ${cleanValue(
        values.description
      )}\n\n${listProducts}\n\nTotal: ${subTotal
        .map(
          (st) =>
            `${printPriceWithCommasAndPeriods(st.prices)} ${st.codeCurrency}`
        )
        .join(" ")}`;
      url += `&text=${encodeURI(payload)}`;

      window.open(url);
      setShowModalCheckoutForm(false);
    }

    // Cleaning CarShop after ordering
    setCartShopping([]);
    setOpen(false);
  };

  const showProduct = (value: ProductInterface) => {
    setSeletedProduct(value);
    setShowModal(true);
  };

  const slideLeft = () => {
    let slider: any = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    let slider: any = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const slideLeftSuggestedProducts = () => {
    let slider: any = document.getElementById("sliderSuggested");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRightSuggestedProducts = () => {
    let slider: any = document.getElementById("sliderSuggested");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const slideLeftOfertedProducts = () => {
    let slider: any = document.getElementById("sliderOferted");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRightOfertedProducts = () => {
    let slider: any = document.getElementById("sliderOferted");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();

    dispatch(setSearchInputValue(normalizeString(e.target.value)));

    dispatch(setResultState(true));

    let productsResults: ProductInterface[] = [];

    products_with_no_salesCategories_FilteredByCurrencyType().data.map(
      (prod) => {
        if (
          normalizeString(prod.name).includes(normalizeString(e.target.value))
        ) {
          productsResults.push(prod);
        }
      }
    );

    productsFilteredByCurrencyType().map((prod) =>
      prod?.data.map((dat) => {
        if (
          normalizeString(dat.name).includes(normalizeString(e.target.value))
        ) {
          productsResults.push(dat);
        }
      })
    );

    setProductResults(productsResults);

    scrollToSection("resultSection");
  };

  const handle_online_shop_main_currency_sortOptions = (index: number) => {
    set_online_shop_main_currency((prevOptions) =>
      prevOptions.map((sortOption, i) => {
        if (i === index && !sortOption.current) {
          // aplying_online_shop_main_currency_options(sortOption.value)
          dispatch(setCurrentCurrency(sortOption.value));
          return {
            ...sortOption,
            current: true,
          };
        } else if (i !== index && sortOption.current) {
          return {
            ...sortOption,
            current: false,
          };
        } else {
          return sortOption;
        }
      })
    );
  };

  if (searchInputValue === "") {
    dispatch(setResultState(false));
  }

  if (isLoading) {
    return <Loading />;
  }

  if (pageNotFound404) {
    return <PageNotFound />;
  }

  // console.log(products.find(e => e.title === "Tartas")?.data.filter(e => e.name !== "Personalización de tartas"))
  // console.log(products.find(e => e.title === "Tartas")?.data.find(e => e.id === 19722))
  // console.log(productsFilteredByCurrencyType().find(elem => elem.title === "Desayunos")?.data.find(elem => elem.availableAddons?.length! > 0))
  const when_shop_create_preorder =
    business?.configurationsKey.filter(
      (config) => config.key === "when_shop_create_preorder"
    )[0].value === "true";
  const enable_to_sale_in_negative = business?.configurationsKey.some(
    (config) => config.key === "enable_to_sale_in_negative_in_shop"
  )
    ? business?.configurationsKey.filter(
      (config) => config.key === "enable_to_sale_in_negative_in_shop"
    )[0].value === "true"
    : false;

  return (
    <div className="bg-white" key={slug}>
      {/* Hero section */}
      {actualView === "profile-info" && (
        <ProfileInfo setActualView={setActualView} />
      )}

      {actualView === "checkout-form" && (
        <PaymentWayForm
          setActualView={setActualView}
          products={cartShopping}
          subTotal={subTotal}
          setOpen={setOpen}
          deletedProductFromCart={deletedProductFromCart}
          setCartShopping={setCartShopping}
          online_shop_main_currency={
            online_shop_main_currency.filter((currency) => currency.current)[0]
              .value
          }
          enable_pick_up_in_store={
            business?.configurationsKey.filter(
              (config) => config.key === "enable_pick_up_in_store"
            )[0].value === "true"
          }
          when_shop_create_preorder={when_shop_create_preorder}
        />
      )}

      {actualView === "shop" && (
        <>
          <div className="relative bg-slate-900">
            {/* Decorative image and overlay */}
            <div
              aria-hidden="true"
              className="absolute inset-0 overflow-hidden"
            >
              <img
                src={business?.banner ? business.banner?.src : ""}
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div
              aria-hidden="true"
              className="absolute inset-0 bg-slate-900 opacity-50"
            />

            {/* Navigation */}
            <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0">
              {/* <h1 className="text-4xl uppercase font-bold tracking-tight text-white lg:text-6xl">
                  {business ? business?.name : ""}
                </h1> */}
              <img
                className="h-32 w-auto  md:h-40 md:w-auto"
                src="/El-Gelato-Logo-Colores-1.png"
                alt=""
              // onClick={() => showGallery(0)}
              />
              <p className="mt-4 text-xl text-white">
                {business ? business?.promotionalText : ""}
              </p>
            </div>
          </div>

          <main>
            {/* Suggested product section */}
            {suggestedProductsFilteredByCurrencyType().length > 0 && (
              <section
                aria-labelledby="category-heading"
                className="my-5  sm:pt-16 xl:mx-auto  xl:max-w-full xl:px-8"
              >
                <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 ">
                  <h2
                    id="category-heading"
                    className="text-2xl font-bold tracking-tight text-slate-900"
                  >
                    Para ti
                  </h2>
                </div>

                <div className="mt-4  ">
                  <div className="-my-2">
                    <div className="   sm:px-6 ">
                      <div className="relative flex items-center scrollbar-hide">
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100 "
                          onClick={slideLeftSuggestedProducts}
                          size={"2x"}
                        />

                        <div
                          id="sliderSuggested"
                          className="w-full  h-full  overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                        >
                          {suggestedProductsFilteredByCurrencyType().map(
                            (item, index) => (
                              <CardProduct
                                product={item}
                                onClick={() => showProduct(item)}
                                priceSystemId={1}
                                cardWidth="w-52"
                                addCart={addCart}
                                includeShop={
                                  business?.includeShop ? true : false
                                }
                                key={index}
                                when_shop_create_preorder={
                                  when_shop_create_preorder
                                }
                                enable_to_sale_in_negative={
                                  enable_to_sale_in_negative
                                }
                              />
                            )
                          )}
                        </div>

                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                          onClick={slideRightSuggestedProducts}
                          size={"2x"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* onSale product section */}
            {onSaleProductsFilteredByCurrencyType().length > 0 && (
              <section
                aria-labelledby="category-heading"
                className="pt-12 pb-20  sm:pt-16  xl:mx-auto xl:max-w-full xl:px-8"
              >
                <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 ">
                  <h2
                    id="category-heading"
                    className="text-2xl font-bold tracking-tight text-slate-900"
                  >
                    Ofertas
                  </h2>
                </div>

                <div className="mt-4  ">
                  <div className="-my-2">
                    <div className="   sm:px-6 ">
                      <div className="relative flex items-center scrollbar-hide">
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100 "
                          onClick={slideLeftOfertedProducts}
                          size={"2x"}
                        />

                        <div
                          id="sliderOferted"
                          className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                        >
                          {onSaleProductsFilteredByCurrencyType().map(
                            (item, index) => (
                              <>
                                <CardProduct
                                  product={item}
                                  addCart={addCart}
                                  onClick={() => showProduct(item)}
                                  priceSystemId={1}
                                  cardWidth="w-52"
                                  includeShop={
                                    business?.includeShop ? true : false
                                  }
                                  key={index}
                                  when_shop_create_preorder={
                                    when_shop_create_preorder
                                  }
                                  enable_to_sale_in_negative={
                                    enable_to_sale_in_negative
                                  }
                                />
                              </>
                            )
                          )}
                        </div>

                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                          onClick={slideRightOfertedProducts}
                          size={"2x"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {saleCategories.length > 0 && searchInputValue === "" && (
              <div className="sticky top-14 z-20 bg-white pb-5 ">
                <div className="flex items-center scrollbar-hide">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="opacity-50 mx-2  cursor-pointer hover:opacity-100 "
                    onClick={slideLeft}
                    size={"2x"}
                  />
                  <div
                    id="slider"
                    className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 "
                  >
                    {saleCategories
                      .filter((saleCategory) =>
                        productsFilteredByCurrencyType().find(
                          (item) => item?.id === saleCategory.id
                        )
                      )
                      .map((item) => (
                        <CardCategory category={item} key={item.id} />
                      ))}
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="opacity-50 mx-2 cursor-pointer hover:opacity-100"
                    onClick={slideRight}
                    size={"2x"}
                  />
                </div>
              </div>
            )}

            {searchResultsActive ? (
              <div className="mx-auto max-w-7xl ">
                <div id="resultSection" className="flex items-center">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="opacity-50 mx-2  cursor-pointer hover:opacity-100 "
                    size={"2x"}
                    onClick={() => {
                      dispatch(setResultState(false));
                      dispatch(setSearchInputValue(" "));
                    }}
                  />
                  <h1 className="font-bold  text-slate-500 mt-10 mb-10 flex items-center text-xl sm:text-2xl lg:text-4xl">
                    RESULTADOS{" "}
                    <span className="text-lg sm:text-xl lg:text-3xl ml-4">
                      ({productResults?.length})
                    </span>
                  </h1>
                </div>
                <div className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-5  space-x-8 px-4 sm:px-6 lg:px-2 ">
                  <div className="grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-y-6 md:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-y-6 lg:gap-x-6 xl:grid-cols-5 xl:gap-y-6 xl:gap-x-6">
                    {productResults?.map((product) => (
                      <CardProduct
                        product={product}
                        onClick={() => showProduct(product)}
                        priceSystemId={1}
                        cardWidth={"w-full"}
                        addCart={addCart}
                        includeShop={business?.includeShop ? true : false}
                        key={product.id}
                        when_shop_create_preorder={when_shop_create_preorder}
                        enable_to_sale_in_negative={enable_to_sale_in_negative}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className=" mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8  ">
                {saleCategories.map((item) => (
                  <ProductForCategory
                    storeSection={
                      productsFilteredByCurrencyType().find(
                        (i) => item.id === i?.id
                      )!
                    }
                    addCart={addCart}
                    includeShop={business?.includeShop ? true : false}
                    key={item.id}
                    cakeCustomizationData={productsFilteredByCurrencyType()
                      .flatMap((category) => category.data)
                      .find((item) => item.id === 20118)}
                    // .find(item => item.id === 19722)}
                    volcanicCandelData={productsFilteredByCurrencyType()
                      .flatMap((category) => category.data)
                      .find((item) => item.id === 23350)}
                    ceramicCandelData={productsFilteredByCurrencyType()
                      .flatMap((category) => category.data)
                      .find((item) => item.id === 29734)}
                    when_shop_create_preorder={when_shop_create_preorder}
                    enable_to_sale_in_negative={enable_to_sale_in_negative}
                  />
                ))}
                {products_with_no_salesCategories_FilteredByCurrencyType().data
                  .length >= 1 && (
                    <ProductForCategory
                      storeSection={
                        products_with_no_salesCategories_FilteredByCurrencyType() !==
                          undefined
                          ? products_with_no_salesCategories_FilteredByCurrencyType()
                          : {
                            id: 0,
                            title: "",
                            data: [],
                          }
                      }
                      addCart={addCart}
                      includeShop={business?.includeShop ? true : false}
                      key={products_with_no_salesCategories?.id}
                      cakeCustomizationData={productsFilteredByCurrencyType()
                        .flatMap((category) => category.data)
                        .find((item) => item.id === 20118)}
                      // .find(item => item.id === 19722)}
                      volcanicCandelData={productsFilteredByCurrencyType()
                        .flatMap((category) => category.data)
                        .find((item) => item.id === 23350)}
                      ceramicCandelData={productsFilteredByCurrencyType()
                        .flatMap((category) => category.data)
                        .find((item) => item.id === 29734)}
                      when_shop_create_preorder={when_shop_create_preorder}
                      enable_to_sale_in_negative={enable_to_sale_in_negative}
                    />
                  )}
              </div>
            )}
          </main>

          {business?.phones[0]?.number && (
            <div className="z-0">
              <FloatingWhatsApp
                avatar={"gelatoIcon.ico"}
                chatMessage={"Hola, ¿Cómo podemos servirle?"}
                statusMessage={""}
                accountName="Gelato"
                phoneNumber={business?.phones[0]?.number!}
              />
            </div>
          )}
        </>
      )}

      <Footer business={business} />

      {showModalGallery && (
        <ModalGallery
          images={business?.images}
          onClick={() => setShowModalGallery(false)}
          index={0}
        />
      )}
      {showModal && (
        <ModalProduct
          product={seletedProduct}
          onClick={() => setShowModal(false)}
          addCart={addCart}
          includeShop={business?.includeShop ? true : false}
          cakeCustomizationData={productsFilteredByCurrencyType()
            .flatMap((category) => category.data)
            .find((item) => item.id === 20118)}
          // .find(item => item.id === 19722)}
          volcanicCandelData={productsFilteredByCurrencyType()
            .flatMap((category) => category.data)
            .find((item) => item.id === 23350)}
          ceramicCandelData={productsFilteredByCurrencyType()
            .flatMap((category) => category.data)
            .find((item) => item.id === 29734)}
          when_shop_create_preorder={when_shop_create_preorder}
          enable_to_sale_in_negative={enable_to_sale_in_negative}
        />
      )}
      {showModalCheckoutForm && (
        <ModalCheckoutForm
          onClick={() => {
            setShowModalCheckoutForm(false);
          }}
          onSendWhatsApp={sendWhatsApp}
        />
      )}

      {showModalRegister && (
        <ModalRegister
          onClick={() => setShowModalRegister(false)}
          setShowModalLogIn={setShowModalLogIn}
        />
      )}

      {showModalLogIn && (
        <ModalLogIn
          onClick={() => {
            setShowModalLogIn(false);
            if (isLoggingToPay) {
              // setActualView("checkout-form")
              scrollToTop();
            }
            setIsLoggingToPay(false);
          }}
          setShowModalRegister={setShowModalRegister}
        />
      )}

      {openLogOutModal && (
        <Modal state={openLogOutModal} close={setOpenLogOutModal}>
          <div className="flex items-center flex-col">
            <span>¿Seguro que desea cerrar sesión?</span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
                setOpenLogOutModal(false);
                logOut();
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {deleteOrderModal.state && (
        <Modal state={deleteOrderModal.state} close={setDeleteOrderModal}>
          <div className="flex items-center flex-col">
            <span className="text-center">
              ¿Seguro que desea cancelar la órden?
            </span>

            <button
              className="bg-red-700 hover:bg-red-800 text-white w-40 h-10 rounded-md mt-4"
              onClick={(e) => {
                e.preventDefault();
                CancelOrder(deleteOrderModal?.id!);
                setDeleteOrderModal({
                  state: false,
                  id: null,
                });
              }}
            >
              Aceptar
            </button>
          </div>
        </Modal>
      )}

      {showInitModal.state && (
        <Modal state={showInitModal.state} close={setshowInitModal}>
          {!showInitModal.degree ? (
            <div>
              <p className="text-center">
                Esta visualizando los precios en{" "}
                <span className="font-semibold">{currentCurrency}</span>. ¿Desea
                continuar en esa moneda?
              </p>

              <div className="flex justify-between w-full gap-x-2">
                <button
                  className="bg-red-500 hover:bg-red-600 text-white w-40 h-10 rounded-md mt-4"
                  onClick={(e) => {
                    setshowInitModal({
                      state: true,
                      degree: true,
                    });
                  }}
                >
                  No
                </button>
                <button
                  className="bg-cyan-500 hover:bg-cyan-600 text-white w-40 h-10 rounded-md mt-4"
                  onClick={(e) => {
                    setshowInitModal({
                      state: false,
                      degree: false,
                    });
                  }}
                >
                  Aceptar
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="mb-4 text-center">
                Seleccione la moneda en la que desea visualizar sus precios.
              </p>

              <div className="w-full flex items-center justify-center">
                {online_shop_main_currency.map((coin, indx) => (
                  <p
                    className="relative cursor-pointer inline-block text-right  mx-2 rounded-md shadow-md bg-gray-800 h-8 px-2 mt-1 pt-1 lg:mt-0 text-white"
                    onClick={() => {
                      handle_online_shop_main_currency_sortOptions(indx);
                      setshowInitModal({
                        state: false,
                        degree: false,
                      });
                    }}
                  >
                    {coin.name}
                  </p>
                ))}
              </div>
            </div>
          )}
        </Modal>
      )}

      <header className="  fixed w-full z-30  top-0">
        {/* Secondary navigation */}
        <div
          className={`bg-slate-900  bg-opacity-60 backdrop-blur-md backdrop-filter`}
        >
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div>
              <div className="flex h-16 items-center justify-between">
                {/* Logo (lg+) */}
                <div className="hidden lg:flex lg:flex-1 lg:items-center">
                  <img
                    className="h-12 w-auto rounded-full"
                    onClick={() => {
                      setActualView("shop");
                    }}
                    src={business?.logo ? business?.logo.src : imageDefault}
                    alt=""
                  />
                </div>

                {/* Logo (lg-) */}
                <p
                  className="lg:hidden"
                  onClick={() => {
                    setActualView("shop");
                  }}
                >
                  <span className="sr-only uppercase">
                    {business ? business.name : ""}
                  </span>
                  <img
                    src={business?.logo ? business?.logo.src : imageDefault}
                    alt=""
                    className="h-10 mr-1 w-auto rounded-full"
                  />
                </p>

                <div className="flex">
                  {/* <SearchInputBar /> */}
                  {actualView !== "checkout-form" && (
                    <form
                      className="flex items-center ml-2"
                      onChange={handleSearchSubmit}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <label className="sr-only">Buscar</label>
                      <div className="relative w-full">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-50 dark:bg-opacity-60 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-slate-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Buscar"
                          autoComplete="off"
                          value={searchInputValue}
                        />
                      </div>
                      {/* <button type="submit" className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg></button> */}
                    </form>
                  )}

                  <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center ">
                      <div className="ml-4 flow-root ">
                        {business?.includeShop && (
                          <button
                            type="button"
                            onClick={() => setOpen(true)}
                            className="group -m-2 flex items-center p-2"
                          >
                            <FontAwesomeIcon
                              icon={faShoppingBag}
                              className="h-6 w-6 flex-shrink-0 text-white"
                              aria-hidden="true"
                            />

                            <span className="ml-2 text-sm font-medium text-white">
                              {cartShopping.length}
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {business?.enableManagementOrders &&
                    actualView !== "checkout-form" && (
                      <div className="flex flex-1 items-center justify-end">
                        <div className="flex items-center ">
                          <div className="ml-4 flow-root ">
                            {business?.includeShop && (
                              <button
                                type="button"
                                onClick={() => setUserOpen(true)}
                                className="group -m-2 flex items-center p-2"
                              >
                                {user?.avatar ? (
                                  <img
                                    className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300 rounded-full"
                                    src={user?.avatar.src}
                                    alt=""
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faUserCircle}
                                    className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {actualView !== "checkout-form" && (
          <div className="flex w-full justify-end lg:block lg:w-auto lg:ml-32 lg:absolute lg:top-5">
            <Menu
              as="div"
              className="relative inline-block text-right mr-1 lg:mr-0 lg:ml-32 rounded-md shadow-md bg-gray-800 h-8 px-2 mt-1 lg:mt-0"
            >
              <div>
                <Menu.Button className="group inline-flex items-center justify-end text-white mt-1">
                  {
                    online_shop_main_currency.find(
                      (option) => option.current === true
                    )?.name
                  }

                  {online_shop_main_currency.length > 1 && (
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white group-hover:text-white"
                      aria-hidden="true"
                    />
                  )}
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`${online_shop_main_currency.length === 1
                      ? "bg-none"
                      : "bg-gray-900 ring-1 ring-white ring-opacity-5"
                    } absolute right-0 z-20 mt-2 w-20 origin-top-right rounded-md  shadow-2xl  focus:outline-none`}
                >
                  <div className="py-1">
                    {online_shop_main_currency.length > 1 &&
                      online_shop_main_currency.map((option, index) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <span
                              className={classNames(
                                option.current
                                  ? "font-medium text-blue-600"
                                  : "text-gray-500",
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm cursor-pointer text-center"
                              )}
                              onClick={() => {
                                handle_online_shop_main_currency_sortOptions(
                                  index
                                );

                                if (cartShopping.length >= 1) {
                                  const aux: ProductCartInterface[] = [];

                                  cartShopping.forEach((cartProd) => {
                                    const productFounded = findProductById(
                                      cartProd.id
                                    );
                                    if (productFounded !== undefined) {
                                      if (
                                        productFounded.prices.some(
                                          (price) =>
                                            price.codeCurrency ===
                                            online_shop_main_currency.find(
                                              (currency, i) => i === index
                                            )?.name
                                        )
                                      ) {
                                        aux.push({
                                          ...cartProd,
                                          //@ts-ignore
                                          price: {
                                            price: productFounded.prices.find(
                                              (price) =>
                                                price.codeCurrency ===
                                                online_shop_main_currency.find(
                                                  (currency, i) => i === index
                                                )?.name
                                            )?.price!,
                                            codeCurrency:
                                              online_shop_main_currency.find(
                                                (currency, i) => i === index
                                              )?.name!,
                                          },
                                        });
                                      }
                                    }
                                  });
                                  setCartShopping(aux);
                                }

                                dispatch(setSearchInputValue(""));

                                dispatch(setInitModalShowed(false));
                              }}
                            >
                              {option.name}
                            </span>
                          )}
                        </Menu.Item>
                      ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </header>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Bolsa de compra
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="mt-8">
                          <div className="flow-root">
                            <ul
                              role="list"
                              className="-my-4 divide-y divide-gray-200"
                            >
                              {cartShopping.map((product) => (
                                <li key={product.id} className="flex py-4">
                                  <div className="h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={
                                        product.images
                                          ? product?.images.src
                                          : imageDefault
                                      }
                                      alt={product.name}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <div className="flex flex-row   w-32 overflow-hidden  ">
                                          <h3 className="">
                                            {product.onSale && (
                                              <FontAwesomeIcon
                                                icon={faTags}
                                                className="ml-1 text-cyan-300"
                                                size="sm"
                                                aria-hidden="true"
                                              />
                                            )}
                                            {product?.suggested && (
                                              <FontAwesomeIcon
                                                icon={faHeart}
                                                className="ml-1 text-cyan-300"
                                                size="sm"
                                                aria-hidden="true"
                                              />
                                            )}{" "}
                                            {product.name}
                                          </h3>
                                        </div>
                                        <div className="ml-4">
                                          {product.onSale && (
                                            <p className={``}>
                                              {printPriceWithCommasAndPeriods(
                                                product.onSalePrice?.amount *
                                                product.quantity
                                              ) +
                                                " " +
                                                product.onSalePrice
                                                  ?.codeCurrency}{" "}
                                            </p>
                                          )}
                                          <p
                                            className={` ${product?.onSale &&
                                              "line-through text-slate-500"
                                              }`}
                                          >
                                            {`${printPriceWithCommasAndPeriods(
                                              product.price.price *
                                              product.quantity
                                            )} ${product.price.codeCurrency}`}
                                          </p>
                                          {product?.addons?.length! > 0 && (
                                            <div>
                                              <p className="text-gray-500 text-sm text-end">
                                                + Agregos
                                              </p>

                                              <p>
                                                {printPriceWithCommasAndPeriods(
                                                  SumAddons(
                                                    product?.addons!,
                                                    currentCurrency!
                                                  ) * product.quantity
                                                ) +
                                                  " " +
                                                  product?.price?.codeCurrency}
                                              </p>

                                              <p className="text-gray-500 text-sm text-end border-t-2 border-gray-500">
                                                Total unitario
                                              </p>

                                              <p
                                                className={` ${product.onSale &&
                                                  "line-through text-slate-500"
                                                  }`}
                                              >
                                                {`${printPriceWithCommasAndPeriods(
                                                  (product.price.price +
                                                    SumAddons(
                                                      product?.addons!,
                                                      currentCurrency!
                                                    )) *
                                                  product.quantity
                                                )} ${product.price.codeCurrency
                                                  }`}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <p className="text-gray-500">
                                        {product.observations ?? ""}
                                      </p>
                                    </div>
                                    <div className="flex flex-1 mt-2 items-end justify-between text-sm">
                                      <div className="flex flex-row items-center">
                                        <FontAwesomeIcon
                                          icon={faMinusCircle}
                                          className="-ml-1 mx-2 text-gray-500 hover:text-rose-500"
                                          size="xl"
                                          aria-hidden="true"
                                          onClick={() => {
                                            if (product.quantity !== 1) {
                                              removeQuantityCart(product);
                                            } else {
                                              deletedProductFromCart(product);
                                            }
                                          }}
                                        />
                                        <input
                                          value={product.quantity}
                                          onChange={(e) =>
                                            quantityProduct(
                                              product,
                                              parseInt(e.target.value)
                                            )
                                          }
                                          name=""
                                          id=""
                                          autoComplete="quantity"
                                          className="block w-10  rounded-md border-0 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm "
                                        />
                                        <FontAwesomeIcon
                                          icon={faPlusCircle}
                                          className=" mx-2 text-gray-500 hover:text-cyan-500"
                                          size="xl"
                                          aria-hidden="true"
                                          onClick={() => {
                                            addProductCart(product);
                                          }}
                                        />

                                        {product.type === "ADDON" ||
                                          product.type === "MENU" ||
                                          product.type === "SERVICE" ||
                                          when_shop_create_preorder ||
                                          enable_to_sale_in_negative
                                          ? false
                                          : product.quantity >
                                          product.quantityInStock && (
                                            <span className="text-red-500">
                                              La cantidad solicitada excede a
                                              la disponible
                                            </span>
                                          )}
                                      </div>

                                      <div className="flex">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            deletedProductFromCart(product)
                                          }
                                          className="font-medium text-rose-500 hover:text-rose-600"
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="-ml-1 mx-2"
                                            size="lg"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      {actualView !== "checkout-form" && (
                        <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <p>Subtotal</p>
                            <p>
                              {subTotal.map((st, key) => (
                                <p className="" key={key}>
                                  {printPriceWithCommasAndPeriods(st.prices)}{" "}
                                  {st.codeCurrency}
                                </p>
                              ))}
                            </p>
                          </div>

                          {!business?.enableManagementOrders && (
                            <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
                              * El precio final puede variar debido a los costos
                              de envío o si solicita una personalización del
                              producto. Por favor, confírmelo con el negocio.
                            </p>
                          )}

                          {business?.configurationsKey.filter(
                            (config) => config.key === "enable_pick_up_in_store"
                          )[0].value === "true" &&
                            minimun_amount_to_buy_with_delivery.find(
                              (element: { codeCurrency: any }) =>
                                element.codeCurrency === currentCurrency
                            )?.amount! >= subTotal[0]?.prices && (
                              <p className="mt-8 text-base text-white md:order-1 md:mt-0 bg-red-500 p-2 rounded-md opacity-80">
                                Su orden debe de ser mayor que{" "}
                                {printPriceWithCommasAndPeriods(
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element: { codeCurrency: any }) =>
                                      element.codeCurrency === currentCurrency
                                  )?.amount!
                                ) +
                                  " " +
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element: any) =>
                                      element.codeCurrency === currentCurrency
                                  )?.codeCurrency}{" "}
                                para poder solicitar mensajería en la compra.
                              </p>
                            )}

                          {business?.configurationsKey.filter(
                            (config) => config.key === "enable_pick_up_in_store"
                          )[0].value === "false" &&
                            minimun_amount_to_buy_with_delivery.find(
                              (element: any) =>
                                element.codeCurrency === currentCurrency
                            )?.amount! >= subTotal[0]?.prices && (
                              <p className="mt-8 text-base text-white md:order-1 md:mt-0 bg-red-500 p-2 rounded-md opacity-80">
                                Su orden debe de ser mayor que{" "}
                                {printPriceWithCommasAndPeriods(
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element: {
                                      codeCurrency: string | null;
                                    }) =>
                                      element.codeCurrency === currentCurrency
                                  )?.amount!
                                ) +
                                  " " +
                                  minimun_amount_to_buy_with_delivery.find(
                                    (element: any) =>
                                      element.codeCurrency === currentCurrency
                                  )?.codeCurrency}{" "}
                                para realizar la compra.
                              </p>
                            )}

                          <div className="mt-6">
                            <div
                              onClick={() => {
                                if (business?.enableManagementOrders) {
                                  if (
                                    (!availabilityAlert ||
                                      when_shop_create_preorder ||
                                      enable_to_sale_in_negative) &&
                                    cartShopping.length > 0 &&
                                    (business?.configurationsKey.filter(
                                      (config) =>
                                        config.key === "enable_pick_up_in_store"
                                    )[0].value === "true"
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.length <
                                        1
                                        ? true
                                        : minimun_amount_to_buy_with_delivery.find(
                                          (element: any) =>
                                            element.codeCurrency ===
                                            currentCurrency
                                        )?.amount! < subTotal[0]?.prices)
                                  ) {
                                    if (user !== undefined && user !== null) {
                                      setOpen(false);
                                      setActualView("checkout-form");
                                      scrollToTop();
                                    } else {
                                      setIsLoggingToPay(true);
                                      setShowModalLogIn(true);
                                    }
                                  }
                                } else {
                                  if (
                                    !availabilityAlert &&
                                    cartShopping.length > 0 &&
                                    (minimun_amount_to_buy_with_delivery.length <
                                      1
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.find(
                                        (element: any) =>
                                          element.codeCurrency ===
                                          currentCurrency
                                      )?.amount! < subTotal[0]?.prices)
                                  ) {
                                    // setOpen(false)
                                    setShowModalCheckoutForm(true);
                                  }
                                }
                              }}
                              className={`${(!availabilityAlert ||
                                  when_shop_create_preorder ||
                                  enable_to_sale_in_negative) &&
                                  cartShopping.length > 0 &&
                                  (business?.enableManagementOrders
                                    ? business?.configurationsKey.filter(
                                      (config) =>
                                        config.key === "enable_pick_up_in_store"
                                    )[0].value === "true"
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.length <
                                        1
                                        ? true
                                        : minimun_amount_to_buy_with_delivery.find(
                                          (element: any) =>
                                            element.codeCurrency ===
                                            currentCurrency
                                        )?.amount! < subTotal[0]?.prices
                                    : minimun_amount_to_buy_with_delivery.length <
                                      1
                                      ? true
                                      : minimun_amount_to_buy_with_delivery.find(
                                        (element: any) =>
                                          element.codeCurrency === currentCurrency
                                      )?.amount! < subTotal[0]?.prices)
                                  ? "hover:bg-cyan-600 bg-cyan-500 cursor-pointer"
                                  : "bg-cyan-200"
                                } flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm `}
                            >
                              <FontAwesomeIcon
                                icon={faBagShopping}
                                className="mx-2 text-white"
                                size="sm"
                                aria-hidden="true"
                              />

                              {business?.enableManagementOrders
                                ? user === undefined || user === null
                                  ? "Ir a iniciar sesión"
                                  : "Finalizar compra"
                                : "Ordenar vía whatsapp"}
                            </div>
                            {business?.enableManagementOrders &&
                              (user === undefined || user === null) && (
                                <p className="mt-6 flex justify-center text-sm font-medium text-red-500">
                                  Para finalizar su compra debe iniciar sesión
                                </p>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={userOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setUserOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Usuario
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setUserOpen(false)}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        {!user ? (
                          <div className="w-full mt-20 flex flex-col justify-center">
                            <h2 className="font-semibold text-3xl text-center">
                              Bienvenid@
                            </h2>

                            <div className="w-full flex justify-center">
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                className="h-32 w-32 mt-10 flex-shrink-0 text-gray-300"
                                aria-hidden="true"
                              />
                            </div>

                            <div className="mt-6">
                              <div
                                onClick={() => {
                                  if (
                                    !availabilityAlert ||
                                    when_shop_create_preorder ||
                                    enable_to_sale_in_negative
                                  ) {
                                    setShowModalLogIn(true);
                                  }
                                }}
                                className={
                                  "hover:bg-cyan-600 bg-cyan-500 cursor-pointer flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm"
                                }
                              >
                                Iniciar sesión
                              </div>
                            </div>

                            {/* <p className="text-center px-6 py-3 mt-3">ó</p> */}

                            <div className="mt-6">
                              <div
                                onClick={() => {
                                  if (
                                    !availabilityAlert ||
                                    when_shop_create_preorder ||
                                    enable_to_sale_in_negative
                                  ) {
                                    setShowModalRegister(true);
                                  }
                                }}
                                className={
                                  "hover:bg-cyan-600 bg-cyan-500 cursor-pointer flex items-center justify-center rounded-md border border-transparent  px-6 py-3 text-base font-medium text-white shadow-sm"
                                }
                              >
                                Registrarte
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="w-full mt-10 flex flex-col justify-center">
                            <div className="w-full flex justify-start">
                              {user.avatar ? (
                                <img
                                  className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300 rounded-full"
                                  src={user.avatar.src}
                                  alt=""
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faUserCircle}
                                  className="h-10 w-10 mx-2 flex-shrink-0 text-gray-300"
                                  aria-hidden="true"
                                />
                              )}

                              <p className="text-lg">
                                {user.displayName
                                  ? user.displayName
                                  : user.email}
                              </p>
                            </div>

                            <div className="w-full mt-2 flex justify-between">
                              <button
                                className="mx-4 my-4 text-red-500"
                                onClick={() => {
                                  setOpenLogOutModal(true);
                                }}
                              >
                                Cerrar sesión
                                <FontAwesomeIcon
                                  icon={faSignOut}
                                  className="ml-1 text-red-500"
                                  size="sm"
                                  aria-hidden="true"
                                />
                              </button>

                              <button
                                className="mx-4 my-4 text-blue-500"
                                onClick={() => {
                                  setUserOpen(false);
                                  setActualView("profile-info");
                                }}
                              >
                                Ver información
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="ml-1 text-blue-500"
                                  size="sm"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>

                            <h2 className="text-3xl font-semibold mt-20 capitalize">
                              última <span className="lowercase">compra</span>
                            </h2>
                            <p className="text-gray-400">
                              Compruebe el estado de los pedidos recientes,
                              gestione las devoluciones y descubra productos
                              similares.
                            </p>

                            {/* <button
                                className="mx-4 my-4 text-blue-500"
                                onClick={() => {
                                  setUserOpen(false);

                                  setActualView("orders-history")
                                }}
                              >
                                Ver historial de órdenes
                                <FontAwesomeIcon
                                  icon={
                                    faArrowRight
                                  }
                                  className="ml-1 text-blue-500"
                                  size="sm"
                                  aria-hidden="true"
                                />
                              </button> */}

                            <Link
                              to={"/orders_historial"}
                              className="mx-4 my-4 text-blue-500 cursor-pointer"
                              onClick={() => {
                                scrollToTop();
                              }}
                            >
                              Ver historial de órdenes
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="ml-1 text-blue-500"
                                size="sm"
                                aria-hidden="true"
                              />
                            </Link>

                            <div className="mt-10 space-y-16 ">
                              {orders !== undefined && orders?.length >= 1 ? (
                                <>
                                  {orders
                                    ?.map((order) => (
                                      <section
                                        className="ring-1 ring-slate-200 rounded-md py-4 px-4"
                                        key={order.operationNumber}
                                        aria-labelledby={`${order.operationNumber}-heading`}
                                      >
                                        <dl className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-3 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                                          <div className="flex justify-between md:block">
                                            <dt className="font-medium text-gray-900">
                                              Orden
                                            </dt>
                                            <dd className="md:mt-1">
                                              {order.operationNumber}
                                            </dd>
                                          </div>
                                          {order?.shippingPrice?.amount > 0 && (
                                            <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                              <dt className=" whitespace-nowrap">
                                                Envío
                                              </dt>
                                              <dd className="md:mt-1 whitespace-nowrap">
                                                {printPriceWithCommasAndPeriods(
                                                  order?.shippingPrice?.amount
                                                ) +
                                                  " " +
                                                  order?.shippingPrice
                                                    ?.codeCurrency}
                                              </dd>
                                            </div>
                                          )}
                                          <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                                            <dt>Total</dt>
                                            <dd className="md:mt-1">
                                              {printPriceWithCommasAndPeriods(
                                                order?.totalToPay[0]?.amount
                                              ) +
                                                " " +
                                                order?.totalToPay[0]
                                                  ?.codeCurrency}
                                            </dd>
                                          </div>

                                          <div className="col-span-2">
                                            <div className="flex-auto space-y-4 divide-y divide-gray-200 text-sm text-gray-600 md:grid md:grid-cols-2 md:gap-x-6 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-8">
                                              <div className=" col-span-1  pt-4 md:block md:pt-0">
                                                <dt className="font-medium text-gray-900 mt-2  ">
                                                  Creada
                                                </dt>
                                                <dd className="md:mt-1">
                                                  <time
                                                    className="text-gray-500 font-semibold"
                                                    dateTime={order.createdAt}
                                                  >
                                                    {formatDateTime(
                                                      order.createdAt
                                                    )}
                                                  </time>
                                                </dd>
                                              </div>

                                              <div className=" col-span-1  pt-4 font-medium text-gray-900 md:block md:pt-0">
                                                <dt className="mt-2  whitespace-nowrap">
                                                  Estado
                                                </dt>
                                                <dd className="md:mt-1 text-gray-500 font-semibold  whitespace-nowrap">
                                                  {filterOrdenStatus(
                                                    order?.status
                                                  )}
                                                </dd>
                                              </div>
                                            </div>
                                          </div>
                                        </dl>
                                        <div className="mb-2 mt-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
                                          {order.selledProducts.map(
                                            (product) => {
                                              const matchedProduct =
                                                findMatchingProduct(
                                                  product.name,
                                                  products
                                                );

                                              return (
                                                <div
                                                  key={product.id}
                                                  className="pt-6 sm:flex sm:flex-col"
                                                >
                                                  <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                                                    <img
                                                      src={
                                                        product.image?.src
                                                          ? product.image.src
                                                          : imageDefault
                                                      }
                                                      alt={
                                                        product.image?.src
                                                          ? product.image.src
                                                          : imageDefault
                                                      }
                                                      className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-32 sm:w-32"
                                                    />
                                                    <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                                                      <h3 className="text-sm font-medium text-gray-900">
                                                        {product.name}
                                                      </h3>
                                                      <p className="truncate text-sm text-gray-500">
                                                        <span>
                                                          {product.quantity +
                                                            " U"}
                                                        </span>
                                                      </p>
                                                      <p className="mt-1 font-medium text-gray-900">
                                                        {product?.priceUnitary
                                                          ?.amount +
                                                          " " +
                                                          product?.priceUnitary
                                                            ?.codeCurrency}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {matchedProduct !==
                                                    undefined && (
                                                      <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                                        <button
                                                          type="button"
                                                          className="flex w-32 sm:w-40 whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-600 bg-cyan-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                                          onClick={() =>
                                                            addCart(
                                                              matchedProduct
                                                            )
                                                          }
                                                        >
                                                          Comprar otra vez
                                                        </button>
                                                      </div>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                        {order?.paymentGateway?.code ===
                                          "G_TROPIPAY" &&
                                          veryfingIfRecreateTppLink(
                                            order.status
                                          ) && (
                                            <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                              <button
                                                type="button"
                                                className="flex w-full whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 bg-blue-500 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                                onClick={() => {
                                                  CreateTropipayLink(order.id);
                                                }}
                                              >
                                                Volver a generar link de pago
                                              </button>
                                            </div>
                                          )}
                                        {(order.status === "PAYMENT_PENDING" ||
                                          order.status === "CREATED") && (
                                            <div className="mt-6 sm:mt-4 flex items-center justify-end w-full">
                                              <button
                                                type="button"
                                                className="flex w-full whitespace-nowrap h-10 items-center justify-center rounded-md border border-transparent  px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 bg-red-600 focus:outline-none focus:ring-2  focus:ring-offset-2 sm:flex-grow-0"
                                                onClick={() => {
                                                  setDeleteOrderModal({
                                                    state: true,
                                                    id: order.id,
                                                  });
                                                }}
                                              >
                                                Cancelar orden
                                              </button>
                                            </div>
                                          )}
                                      </section>
                                    ))
                                    .slice(0, 1)}
                                </>
                              ) : (
                                <p className="text-center text-slate-400 my-10">
                                  No hay ordenes registradas aún
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
